import React from 'react';
import {FormLabel, Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    centerItem: {
        color: "red"
        
    }
}));

function FormContainer(props) {
    const classes = useStyles();
    const formData = props.form;
    const createForm = () => {
        let container = [];
        if (formData) {
            formData.forEach((d, index) => {
                container.push(<Grid key={index} container={true}
                                     spacing={3}>
                    {createGrids(d)}
                </Grid>)
            });
            return container;
        }
    }

    const createGrids = (data) => {
        let grid = [];
        data.forEach((d, index) => {
            grid.push(<Grid key={index} item={true}
                            xs={12}
                            sm={12/data.length}>
                <Grid container={true}
                    spacing={3}
                    alignItems="center"
                    justifyContent='center'
                    style={{ paddingRight: '0%', paddingLeft: '0%' }}
                >
                    <Grid item={true}
                        xs={12}
                        sm={3}
                        container
                        justifyContent='flex-end'
                    >
                        <FormLabel>{d.label}</FormLabel>
                    </Grid>
                    <Grid item={true}
                        xs={12}
                        sm={6}
                        className={classes.centerItem}
                    >
                        {controlType(d)}
                    </Grid>
                </Grid>
            </Grid>)
        });
        return grid
    }

    const controlType = (data) => {
        if (data.type === 'input') {
            return <TextField name={data.name}
                              value={data.value}
                              fullWidth={false}
                              disabled={data.disabled}
                              onChange={data.handle}
                              variant={data.variant}/>
        } else if(data.type === 'datetime-local') {
            return <TextField type={data.type}
                              defaultValue={data.value}
                              InputProps={{inputProps: { min: data.value}}}
                              InputLabelProps={{shrink: true}}
                              onChange={data.handle}
            />
        } else if (data.type === 'select') {
            return <Select fullWidth={false}>
                {buildSelects(data)}
            </Select>
        }
    }

    const buildSelects = (data) => {
        let items = [];
        let val = data.value;
        val.forEach((d, index) => {
            items.push(<MenuItem key={index} value={d[data.name+'Id']}>{d[data.name]}</MenuItem> )
        });
        return items;
    }

    return(
        <div>
            {createForm()}
        </div>
    );
}

export default FormContainer;