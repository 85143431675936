import React from "react";
import { Grid } from "@material-ui/core";
import FormContainer from "../FormContainer";
import Card from "../muiFacade/Card";
import CardHeader from "../muiFacade/CardHeader";
import CardContent from "../muiFacade/CardContent";

const AddressCard = ({ data }) => {
  const addressForm = () => {
    return [
      [
        {
          label: "Estado",
          name: "state",
          value: data.commercialAddress
            ? data.commercialAddress.state
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Estado",
          name: "state",
          value: data.fiscalAddress ? data.fiscalAddress.state : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Localidad",
          name: "locality",
          value: data.commercialAddress
            ? data.commercialAddress.locality
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Localidad",
          name: "locality",
          value: data.fiscalAddress ? data.fiscalAddress.locality : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Municipio",
          name: "municipality",
          value: data.commercialAddress
            ? data.commercialAddress.municipality
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Municipio",
          name: "municipality",
          value: data.fiscalAddress
            ? data.fiscalAddress.municipality
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Código postal",
          name: "zipCode",
          value: data.commercialAddress
            ? data.commercialAddress.zipCode
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Código postal",
          name: "zipCode",
          value: data.fiscalAddress ? data.fiscalAddress.zipCode : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Colonia",
          name: "neighborhood",
          value: data.commercialAddress
            ? data.commercialAddress.neighborhood
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Colonia",
          name: "neighborhood",
          value: data.fiscalAddress
            ? data.fiscalAddress.neighborhood
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Calle",
          name: "street",
          value: data.commercialAddress
            ? data.commercialAddress.street
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Calle",
          name: "street",
          value: data.fiscalAddress ? data.fiscalAddress.street : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Número exterior",
          name: "exNum",
          value: data.commercialAddress
            ? data.commercialAddress.exNum || ""
            : "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Número exterior",
          name: "exNum",
          value: data.fiscalAddress ? data.fiscalAddress.exNum || "" : "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Número interior",
          name: "inNum",
          value: data.commercialAddress
            ? data.commercialAddress.inNum || ""
            : "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Número interior",
          name: "inNum",
          value: data.fiscalAddress ? data.fiscalAddress.inNum || "" : "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Teléfono",
          name: "phoneNumber",
          value: data.commercialAddress
            ? data.commercialAddress.phoneNumber
            : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Teléfono",
          name: "phoneNumber",
          value: data.fiscalAddress ? data.fiscalAddress.phoneNumber : "" || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
    ];
  };

  return (
    <Card>
      <Grid container spacing={3} style={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={6}>
          <CardHeader title={"Dirección Comercial"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardHeader title={"Dirección Fiscal"} />
        </Grid>
      </Grid>
      <CardContent>
        <FormContainer form={addressForm()} />
      </CardContent>
    </Card>
  );
};

export default AddressCard;
