import React from "react";
import { Grid, FormLabel, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: "775px",
    marginTop: 20,
  },
  cardHeader: {
    textAlign: "center",
    color: "#FF995B",
  },
  cardContainer: {
    margin: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const LabeledTextField = ({
  textFieldLabel,
  xs,
  sm,
  md,
  lg,
  xl,
  ...textFieldProps
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    >
      <Grid item xs={12} sm={3} container justifyContent="flex-end">
        <FormLabel style={{ textOverflow: "ellipsis" }}>
          {textFieldLabel}
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centerItem}>
        <TextField {...textFieldProps} />
      </Grid>
    </Grid>
  );
};

export default LabeledTextField;
