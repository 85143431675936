import React from 'react';
import LoadingScreen from 'react-loading-screen';

function Spinner() {
    return (
        <LoadingScreen
            loading={true}
            bgColor='#f1f1f1'
            spinnerColor='#ff995b'
            textColor='#a6a6a6'
            logoSrc='https://elasticbeanstalk-us-east-1-460503004594.s3.amazonaws.com/upaxer/administrator/resources/logoUpaxer.png'>
            {<div style={{height: '100%'}}></div>}
        </LoadingScreen>
    )
}

export default Spinner;