import React from "react";
import { Select, FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  rojo: {
    "& .MuiSelect-select": {
      background: "white",
    },
    " & .MuiOutlinedInput-root": {
      height: "auto",
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9B9B99",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF995B",
      color: "#FF995B",
    },
  },
  azul: {},
  verde: {
    width: "10rem",
  },
}));

export default function ({ label, children, ...rest }) {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" classes={{ root: classes.rojo }}>
      <InputLabel
        htmlFor="Age"
        variant="outlined"
        classes={{ root: classes.azul }}
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        inputProps={{
          name: "Prueba",
          id: "outlined-age-native-simple",
        }}
        classes={{ root: classes.verde }}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  );
}
