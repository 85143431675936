import React from "react";
import Card from "../muiFacade/Card";
import CardHeader from "../muiFacade/CardHeader";
import CardContent from "../muiFacade/CardContent";

import FormContainer from "../FormContainer";

const ContactInfoCard = ({ data }) => {
  const contactForm = () => {
    return [
      [
        {
          label: "Nombre",
          name: "userName",
          value: data.userName || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Posición",
          name: "posotion",
          value: data.position || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Apellido paterno",
          name: "paternalSurname",
          value: data.paternalSurname || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Teléfono",
          name: "phoneNumber",
          value: data.phoneNumber || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "Apellido materno",
          name: "maternalSurname",
          value: data.maternalSurname || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Correo",
          name: "email",
          value: data.email || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
    ];
  };

  return (
    <Card>
      <CardHeader title={"Información de contacto"} />
      <CardContent>
        <FormContainer form={contactForm()} />
      </CardContent>
    </Card>
  );
};

export default ContactInfoCard;
