import React from "react";
import Card from "../muiFacade/Card";
import CardHeader from "../muiFacade/CardHeader";
import CardContent from "../muiFacade/CardContent";

import FormContainer from "../FormContainer";

const CompanyCard = ({ data }) => {
  const companyForm = () => {
    return [
      [
        {
          label: "Nombre",
          name: "company",
          value: data.company || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Comercial",
          name: "commercial",
          value: data.commercial || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
      [
        {
          label: "RFC",
          name: "rfc",
          value: data.rfc || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
        {
          label: "Actividad",
          name: "activity",
          value: data.activity || "",
          variant: "filled",
          disabled: true,
          type: "input",
        },
      ],
    ];
  };

  return (
    <Card>
      <CardHeader title={"Empresa"} />
      <CardContent>
        <FormContainer form={companyForm()} />
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
