import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const headColumns = [
  "TAREAS",
  "",
  "",
  "INFORMACIÓN CONTACTO",
  "",
  "",
  "",
  "",
  "",
  "PROGRAMACIÓN DE CITA",
  "",
  "",
  "EMPRESA",
  "",
  "",
  "",
  "",
  "DIRECCIÓN COMERCIAL",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "DIRECCIÓN FISCAL",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  //  "UBICACIÓN",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
  //  "",
];

const columns = [
  //"TAREAS",
  "FOLIO",
  "PRIORIDAD",
  "TIPO CITA",
  //"INFORMACIÓN CONTACTO",
  "NOMBRE",
  "APELLIDO PATERNO",
  "APELLIDO MATERNO",
  "POSICIÓN",
  "TELÉFONO",
  "CORREO",
  //"PROGRAMACIÓN DE CITA",
  "FECHA PROGRAMACIÓN",
  "FECHA VISITA",
  "OBSERVACIONES",
  //"EMPRESA",
  "NOMBRE",
  "EMPRESA",
  "RFC",
  "COMERCIAL",
  "ACTIVIDAD",
  //"DIRECCIÓN COMERCIAL",
  "ESTADO",
  "LOCALIDAD",
  "MUNICIPIO",
  "CP",
  "COLONIA",
  "CALLE",
  "NO. EXTERIOR",
  "NO. INTERIOR",
  "TELÉFONO",
  //"DIRECCIÓN FISCAL",
  "ESTADO",
  "LOCALIDAD",
  "MUNICIPIO",
  "CP",
  "COLONIA",
  "CALLE",
  "NO. EXTERIOR",
  "NO. INTERIOR",
  "TELÉFONO",
  //"UBICACIÓN",
  "PROYECTO",
  "UBICACIÓN",
  "ESTRUCTURA",
  "ESTADO",
  "COLONIA",
  "LONGITUD",
  "DIRECCIÓN",
  "CLAVE",
  "CANAL",
  "MUNICIPIO",
  "CALLE",
  "LONGITUD",
];

const Excel = React.forwardRef(({ data }, ref) => {
  const formatedData = data.map((it) => [
    it.visitFile,
    it.priority,
    it.citeType,
    it.userName,
    it.paternalSurname,
    it.maternalSurname,
    it.position,
    it.phoneNumber,
    it.email,
    //"PROGRAMACIÓN DE CITA",
    it.programDate,
    it.visitDate,
    it.comments,
    //"EMPRESA",
    it.company,
    it.companyId,
    it.rfc,
    it.commercial,
    it.activity,
    //"DIRECCIÓN COMERCIAL",
    it.commercialAddress.state,
    it.commercialAddress.locality,
    it.commercialAddress.municipality,
    it.commercialAddress.municipality,
    it.commercialAddress.zipCode,
    it.commercialAddress.neighborhood,
    it.commercialAddress.street,
    it.commercialAddress.exNum,
    it.commercialAddress.inNum,
    it.commercialAddress.phoneNumber,
    //"DIRECCIÓN FISCAL",
    it.fiscalAddress.state,
    it.fiscalAddress.locality,
    it.fiscalAddress.municipality,
    it.fiscalAddress.zipCode,
    it.fiscalAddress.neighborhood,
    it.fiscalAddress.street,
    it.fiscalAddress.exNum,
    it.fiscalAddress.inNum,
    it.fiscalAddress.phoneNumber,
    //"UBICACIÓN",
    // "PROYECTO",
    // "UBICACIÓN",
    // "ESTRUCTURA",
    // "ESTADO",
    // "COLONIA",
    // "LONGITUD",
    // "DIRECCIÓN",
    // "CLAVE",
    // "CANAL",
    // "MUNICIPIO",
    // "CALLE",
    // "LONGITUD",
  ]);

  const multiDataSet = [
    {
      columns: headColumns,
      data: [],
    },
    {
      xSteps: 0, // Will start putting cell with 1 empty cell on left most
      ySteps: 0, //will put space of 5 rows,
      columns: columns,
      data: formatedData,
    },
  ];

  return (
    <ExcelFile
      element={
        <a ref={ref} style={{ display: "none" }}>
          Hola
        </a>
      }
    >
      <ExcelSheet dataSet={multiDataSet} name="Organization" />
    </ExcelFile>
  );
});

export default Excel;

export const example = {
  activity: "48-49",
  citeType: "Advanced",
  comments: null,
  commercial: "MASREC SCREEN",
  commercialAddress: {
    country: "1",
    exNum: "45",
    inNum: "87",
    latitude: 0.0,
    locality: "TLALPAN",
    longitude: 0.0,
    municipality: "Guadalajara",
    neighborhood: "Jardines del Bosque Centro",
    phoneNumber: "3216861080",
    state: "Jalisco",
    street: "CEDROS",
    zipCode: "44520",
    zipCodeId: 76882,
  },
  company: "SOLUTIONS SA DE CV",
  companyId: 1150,
  email: "fangeles@linko.mx",
  fiscalAddress: {
    country: "1",
    exNum: "45",
    inNum: "87",
    latitude: 0.0,
    locality: "TLALPAN",
    longitude: 0.0,
    municipality: "Guadalajara",
    neighborhood: "Jardines del Bosque Centro",
    phoneNumber: "3216861080",
    state: "Jalisco",
    street: "CEDROS",
    zipCode: "44520",
    zipCodeId: 76882,
  },
  maternalSurname: "BOTERO",
  paternalSurname: "CARVAJAL",
  phoneNumber: "9504878082",
  position: "COO",
  priority: "Media",
  programDate: "2022-11-17 13:35:00",
  rfc: "ZMXNCVB384JSH",
  serviceId: 1009,
  statusId: 2,
  userName: "JAVIER",
  visitDate: "2022-11-17 13:46:00",
};
