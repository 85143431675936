import React from "react";
import { Grid, CardContent } from "@material-ui/core";
import Card from "../muiFacade/Card";
import LabeledTextField from "../LabeledTextField";
import FolioTextField from "../FolioTextField";
import CardHeader from "../muiFacade/CardHeader";

const DetailsCard = ({ data }) => {
  return (
    <Card>
      <CardHeader title={"Detalle"} />
      <CardContent>
        <Grid container spacing={1}>
          <FolioTextField sm visitFile={data.visitFile || ""} />
          <LabeledTextField
            textFieldLabel={"Prioridad"}
            name={"priority"}
            value={data.priority || ""}
            variant={"filled"}
            disabled={true}
            sm
          />
          <LabeledTextField
            textFieldLabel={"Tipo de Cita"}
            name={"citeType"}
            value={data.citeType || ""}
            variant={"filled"}
            disabled={true}
            sm
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DetailsCard;
