import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const OrangeButton = styled(Button)({
  border: 0,
  color: "white",
  fontStyle: "normal",
  fontWeight: 700,
  background: "#FF995B",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  textTransform: "Capitalize",
  "&:hover": {
    background: "#FF7C1B",
  },
  "&.Mui-disabled": {
    color: "white",
  },
});

export default OrangeButton;
