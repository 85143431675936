import React from "react";
import CheckIcon from "@material-ui/icons/Check";

export default function ({ type, value=true }) {
  let color;
  switch (type) {
    case "onTime":
    case "verde":
      color = "#66BB6A";
      break;
    case "expired":
    case "rojo":
      color = "#D33604";
      break;
    case "aboutToExpire":
    case "amarillo":
      color = "#FFDB5B";
      break;
    case "done":
    case "naranja":
      color = "#FF995B";
      break;
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        height: "16px",
        width: "16px",
        background: value ? color : "white",
        borderRadius: "50%",
        margin: "0 auto",
        border: value ? "none" : `1px solid ${color}`,
      }}
    >
      {type === "done" && (
        <CheckIcon style={{ height: "16px", width: "16px", color: "white" }} />
      )}
    </div>
  );
}
