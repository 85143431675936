import React from "react";
import { Grid, Box, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    minWidth: "775px",
    marginTop: 20,
  },
  cardHeader: {
    textAlign: "center",
    color: "#FF995B",
  },
  cardContainer: {
    margin: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FolioTextField = ({ visitFile, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      spacing={1}
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      <Grid item justifyContent={"flex-end"}>
        <Box
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#FF995B",
          }}
        >
          Folio
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#9B9B99",
          }}
        >
          {visitFile}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FolioTextField;
