import React, {Component} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends Component {

    onMarkerDragEnd = (one, two, three) => {
        const { latLng } = three;
        const lat = latLng.lat();
        const lng = latLng.lng();
        this.props.setLat(lat);
        this.props.setLon(lng);
    }

    render() {
        return (
            <Map google={this.props.google}
                 style={{width: '100%'}}
                 zoom={15}
                 initialCenter={{
                     lat: this.props.lan,
                     lng: this.props.lon
                 }}
                 center={{
                     lat: this.props.lan,
                     lng: this.props.lon
                 }}>
                <Marker
                    name={'Current location'}
                    position={{lat: this.props.lan, lng: this.props.lon}}
                    draggable={true} onDragend={this.onMarkerDragEnd}/>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAC0IGADj7ia-KnaU8hrCcnBE2m7MJBGNw')
})(MapContainer)