import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Visit from './page/Visit';
import ContactInfo from './page/ContactInfo';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

function App() {
    return(
        <div >
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Switch>
                        <Route path={'/'} exact={true} component={Visit}/>
                        <Route path={'/contact-info'} component={ContactInfo}/>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;