import React from "react";
import { Grid, Button } from "@material-ui/core";
//import Button from "../muiFacade/Button";
import LabeledTextField from "../LabeledTextField";
import Card from "../muiFacade/Card";
import CardHeader from "../muiFacade/CardHeader";
import CardContent from "../muiFacade/CardContent";
import DateRangeIcon from "@material-ui/icons/DateRange";

import { changeVisitDate } from "../../service/VisitService";

import {
  PENDING,
  REVIEW,
  INPROGRESS,
  REJECTED,
  ENDED,
} from "../../utils/status";

const DatesCard = ({
  serviceId,
  data,
  handleDateChange,
  setError,
  setInformative,
  setSpinner,
}) => {
  const status = data.statusId;

  const visitDate = data.visitDate ? data.visitDate.split(" ")[0] : "";
  const isVisitDateDisabled = status === REJECTED || status === ENDED;
  const minVisitDate = data.programDate ? data.programDate.split(" ")[0] : null;

  const programDate = data.programDate ? data.programDate.split(" ")[0] : "";
  const isProgramDateDisabled =
    status === INPROGRESS || status === REJECTED || status === ENDED;

  const comments = data.comments || "";
  const isCommentsDisabled = status === REJECTED || status === ENDED;

  const canVisitDateChange = status === INPROGRESS;

  const maxDate = "2040-01-01";

  const handleVisitDateChange = () => {
    console.log("newVisitDate", visitDate);
    setSpinner(true);
    changeVisitDate(serviceId, visitDate)
      .then((resp) => {
        setInformative("La fecha cambió correctamente.");
      })
      .catch((err) => {
        setError("Hubo un error al cambiar la fecha.");
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  return (
    <Card>
      <CardHeader title={"Programación de visita"} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid container direction="column" spacing={3} item xs={12} sm={6}>
            <LabeledTextField
              textFieldLabel={"Fecha de visita"}
              type={"date"}
              name={"visitDate"}
              disabled={isVisitDateDisabled}
              value={visitDate}
              InputProps={{
                inputProps: {
                  min: minVisitDate,
                  max: maxDate,
                },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
            />
            <LabeledTextField
              textFieldLabel={"Fecha de Programación"}
              type={"date"}
              name={"programDate"}
              value={programDate}
              disabled={isProgramDateDisabled}
              InputProps={{
                inputProps: {
                  max: maxDate,
                },
              }}
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sm={6}
            spacing={3}
          >
            <Grid item>
              <LabeledTextField
                textFieldLabel={"Observaciones"}
                name={"comments"}
                disabled={isCommentsDisabled}
                value={comments}
                onChange={handleDateChange}
              />
            </Grid>
            {canVisitDateChange && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DateRangeIcon />}
                  onClick={handleVisitDateChange}
                  disabled={!data.visitDate}
                >
                  Cambiar fecha de visita
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DatesCard;
