import React from "react";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";

export default function ({ value, ...rest }) {
  return value ? (
    <CheckBox style={{ color: "orange" }} {...rest} />
  ) : (
    <CheckBoxOutlineBlank style={{ color: "orange" }} {...rest} />
  );
}
