import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAC0IGADj7ia-KnaU8hrCcnBE2m7MJBGNw");
Geocode.setLanguage("es");
Geocode.setRegion("es");

const add2Str = (it) =>
  `${it.street} ${it.exNum} ${it.neighborhood} ${it.state} ${it.zipCode}`;

export const getCoordinates = ({ commercialAddress, fiscalAddress }) => {
  const cA = Geocode.fromAddress(add2Str(commercialAddress))
    .then((resp) => resp.results[0].geometry.location)
    .catch((err) => console.error(err));
  const fA = Geocode.fromAddress(add2Str(fiscalAddress))
    .then((resp) => resp.results[0].geometry.location)
    .catch((err) => console.error(err));

  return Promise.all([cA, fA]).then(([comCoor, fisCoor]) => ({
    comCoor,
    fisCoor,
  }));
};
