import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  root: {
    "& .MuiOutlinedInput-root": {
      height: "auto",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9B9B99",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF995B",
      color: "#FF995B",
    },
  },
}));

export default function ({ placeholder, ...rest }) {
  const classes = useStyles();

  return (
    <TextField
      label={placeholder}
      InputLabelProps={{ shrink: true }}
      type={"date"}
      variant="outlined"
      classes={{ root: classes.root, select: classes.select }}
      {...rest}
    />
  );
}
