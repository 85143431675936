import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

import { Link } from "react-router-dom";

import { Grid, InputAdornment, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import LaunchIcon from "@material-ui/icons/Launch";
import CancelIcon from "@material-ui/icons/Cancel";

import Radio from "../component/Radio";
import OrangeButton from "../component/muiFacade/Button";
import ModalError from "../component/ModalError";
import Spinner from "../component/Spinner";
import Select from "../component/muiFacade/Select";
import ColorDot from "../component/ColorDot";
import CheckBox from "../component/muiFacade/CheckBox";
import DatePicker from "../component/muiFacade/DatePicker";
import Excel from "../component/Excel";

import { getVisits, getVisitsInfo } from "../service/VisitService";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 10,
  },
  container: {
    maxHeight: 440,
  },
});

function Visit() {
  const classes = useStyles();
  const [exportData, setExportData] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchType, setSearchType] = useState("allData");
  const [validity, setValidity] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [touched, setTouched] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const excelRef = useRef(null);

  const emptyRows = Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const counterPage = () => {
    let counter = [];
    if (rows.length === 0) {
      counter.push(0);
    } else {
      counter.push(rows.length < 10 ? rows.length : 10);
      if (rows.length > 10) counter.push(rows.length < 20 ? rows.length : 25);
      if (rows.length > 25) counter.push(rows.length < 50 ? rows.length : 50);
      if (rows.length > 50) counter.push(rows.length < 100 ? rows.length : 100);
    }
    return counter;
  };

  // const handleSearch = (e) => {
  //   let result = data.filter(
  //     (d) =>
  //       d.visitFile.toUpperCase().includes(e.target.value.toUpperCase()) ||
  //       d.priority.toUpperCase().includes(e.target.value.toUpperCase()) ||
  //       d.status.toUpperCase().includes(e.target.value.toUpperCase()) ||
  //       d.citeType.toUpperCase().includes(e.target.value.toUpperCase())
  //   );
  //   //setRows(result);
  // };

  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    //console.log("En useEffect", { initialDate, finalDate });
    setFinalDate("");
  }, [initialDate]);

  const onSearch = () => {
    let color = undefined;
    if (validity === "expired") {
      color = "rojo";
    } else if (validity === "aboutToExpire") {
      color = "amarillo";
    } else if (validity === "onTime") {
      color = "verde";
    } else if (validity === "done") {
      color = "naranja";
    }

    let newData = data;

    if (color) {
      newData = newData.filter((it) => it.color === color);
    }

    if (initialDate) {
      const initial = moment(`${initialDate} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
      if (searchType == "allData") {
        newData = newData.filter((it, ind) => {
          const regDate = moment(it.registerDate, "DD/MM/YYYY HH:mm");
          const visDate = moment(it.visitDate, "DD/MM/YYYY HH:mm");
          const inBoundaries = initial < regDate || initial < visDate;
          //if (inBoundaries) console.log("inBound", it.registerDate, it.visitDate);
          return inBoundaries;
        });
      } else if (searchType === "registerDate") {
        newData = newData.filter((it) => {
          const date = moment(it.registerDate, "DD/MM/YYYY HH:mm");
          return date > initial;
        });
      } else if (searchType === "visitDate") {
        newData = newData.filter((it) => {
          const date = moment(it.visitDate, "DD/MM/YYYY HH:mm");
          return date > initial;
        });
      } else {
      }
    }

    if (finalDate) {
      const final = moment(`${finalDate} 23:59:59`, "YYYY-MM-DD HH:mm:ss");
      if (searchType == "allData") {
        newData = newData.filter((it, ind) => {
          const regDate = moment(it.registerDate, "DD/MM/YYYY HH:mm");
          const visDate = moment(it.visitDate, "DD/MM/YYYY HH:mm");
          const inBoundaries = regDate < final || visDate < final;
          //if (inBoundaries) console.log("inBound", it.registerDate, it.visitDate);
          return inBoundaries;
        });
      } else if (searchType === "registerDate") {
        newData = newData.filter((it) => {
          const date = moment(it.registerDate, "DD/MM/YYYY HH:mm");
          return date < final;
        });
      } else if (searchType === "visitDate") {
        newData = newData.filter((it) => {
          const date = moment(it.visitDate, "DD/MM/YYYY HH:mm");
          return date < final;
        });
      } else {
      }
    }

    // if (searchText !== "") {
    //   //const text = searchText.toUpperCase();
    //   const text = new RegExp(searchText, "ig");
    //   newData = newData.filter(
    //     (d) =>
    //       d.visitFile.match(text) ||
    //       d.priority.match(text) ||
    //       d.status.match(text) ||
    //       d.citeType.match(text)
    //   );
    // }

    setFilteredData(newData);
  };

  useEffect(() => {
    let newRows = filteredData;
    if (searchText !== "") {
      //console.log({ filteredData, searchText });
      //const text = searchText.toUpperCase();
      const text = new RegExp(searchText, "ig");
      newRows = filteredData.filter(
        (d) =>
          d.visitFile.match(text) ||
          d.priority.match(text) ||
          d.status.match(text) ||
          d.citeType.match(text)
      );
    }
    setRows(newRows);
  }, [searchText, filteredData]);

  useEffect(() => {
    setSpinner(true);
    getVisits()
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
        setRows(res.data);
      })
      .catch(() => {
        setOpen(true);
      })
      .finally(() => {
        setSpinner(false);
        setTouched(true);
      });
  }, []);

  const onExport = () => {
    //console.log('onExport')
    const ids = Object.keys(selectedRows).filter((it) => selectedRows[it]);

    getVisitsInfo(ids)
      .then((res) => {
        console.log("getVisitsInfo", res);
        const incompleteData = res.data;
        const completeData = incompleteData.map((it) => ({
          ...it,
          visitFile: rows.find((r) => r.serviceId === it.serviceId).visitFile,
        }));
        setExportData(completeData);
        excelRef.current.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRowClick = (row) => () => {
    const id = row.serviceId;
    setSelectedRows((state) => ({ ...state, [id]: !selectedRows[id] }));
  };

  const onSelectAll = (row) => {
    if (!Object.keys(selectedRows)?.every((it) => selectedRows[it])) {
      let acc = {};
      rows.forEach((it) => {
        acc[it.serviceId] = true;
      });
      setSelectedRows(acc);
    } else {
      let acc = {};
      rows.forEach((it) => {
        acc[it.serviceId] = false;
      });
      setSelectedRows(acc);
    }
  };

  useEffect(() => {
    let acc = {};
    rows.forEach((it) => {
      acc[it.serviceId] = false;
    });
    setSelectedRows(acc);
  }, [rows]);

  return (
    <Grid container spacing={3} style={{ width: "100%" }}>
      <Grid item container spacing={3} justifyContent="flex-end">
        <Grid item>
          <OrangeButton
            variant="contained"
            startIcon={<SearchIcon />}
            onClick={onSearch}
            disabled={initialDate === "" || finalDate === ""}
          >
            Buscar
          </OrangeButton>
        </Grid>
        <Grid item>
          <OrangeButton
            variant="contained"
            startIcon={<LaunchIcon />}
            onClick={onExport}
            disabled={!Object.keys(selectedRows).some((it) => selectedRows[it])}
          >
            Exportar
          </OrangeButton>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <TextField
          variant="standard"
          placeholder="Buscar"
          onChange={(ev) => {
            setSearchText(ev.target.value);
          }}
          fullWidth={true}
          margin="normal"
          style={{ background: "#FAFAFA" }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" style={{ color: "#FF995B" }}>
                <SearchIcon />
              </InputAdornment>
            ),
            style: { padding: "9px 15px", borderRadius: "4px" },
          }}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <Select
            label="Buscar"
            value={searchType}
            onChange={(ev) => {
              setSearchType(ev.target.value);
            }}
          >
            <MenuItem value="allData">Todos los datos</MenuItem>
            <MenuItem value="registerDate">Fecha registro</MenuItem>
            <MenuItem value="visitDate">Fecha visita</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <DatePicker
            placeholder="Introduce fecha inicial"
            value={initialDate}
            onChange={(ev) => {
              setInitialDate(ev.target.value);
            }}
          />
        </Grid>
        <Grid item>
          <DatePicker
            placeholder="Introduce fecha final"
            value={finalDate}
            onChange={(ev) => {
              setFinalDate(ev.target.value);
            }}
            inputProps={{ min: initialDate }}
            disabled={!initialDate}
          />
        </Grid>
        <Grid
          item
          direction="column"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: "12px",
            lineHeight: "14px",
          }}
        >
          <div style={{ margin: "4px 8px" }}>Selecciona vigencia:</div>
          <div style={{ display: "flex" }}>
            <Radio
              value={validity === "expired"}
              type={"expired"}
              onChange={() => {
                setValidity(validity !== "expired" ? "expired" : null);
              }}
              label={"vencido"}
            />
            <Radio
              value={validity === "aboutToExpire"}
              type={"aboutToExpire"}
              onChange={() => {
                setValidity(
                  validity !== "aboutToExpire" ? "aboutToExpire" : null
                );
              }}
              label={"por vencer"}
            />
            <Radio
              value={validity === "onTime"}
              type={"onTime"}
              onChange={() => {
                setValidity(validity !== "onTime" ? "onTime" : null);
              }}
              label={"en tiempo"}
            />
            <Radio
              value={validity === "done"}
              type={"done"}
              onChange={() => {
                setValidity(validity !== "done" ? "done" : null);
              }}
              label={"realizada"}
            />
          </div>
        </Grid>
      </Grid>
      {touched &&
        (!rows.length ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                background: "#D33604",
                color: "white",
                padding: "16px",
                borderRadius: "4px",
              }}
            >
              <CancelIcon style={{ marginRight: "10px" }} />
              <div
                style={{
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                No hay coincidencias con la búsqueda
              </div>
            </div>
          </div>
        ) : (
          <>
            <Grid item container>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" checkboxSelection>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        <CheckBox
                          value={true}
                          onClick={onSelectAll}
                          style={{ color: "orange" }}
                        />
                      </TableCell>
                      <TableCell align="center">Folio</TableCell>
                      <TableCell align="center">Fecha de registro</TableCell>
                      <TableCell align="center">Estatus</TableCell>
                      <TableCell align="center">Vigencia</TableCell>
                      <TableCell align="center">Prioridad</TableCell>
                      <TableCell align="center">Tipo de visita</TableCell>
                      <TableCell align="center">Fecha de visita</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? rows.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : rows
                    ).map((row, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell style={{ width: 60 }} align="center">
                          <CheckBox
                            onClick={onRowClick(row)}
                            value={!!selectedRows[row.serviceId]}
                            style={{ color: "orange" }}
                          />
                        </TableCell>
                        <TableCell align="center" style={{ width: "240px" }}>
                          <Link
                            to={{
                              pathname: "/contact-info",
                              state: {
                                visitFile: row.visitFile,
                                serviceId: row.serviceId,
                              },
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ width: "231px" }}
                            >
                              {row.visitFile}
                            </Button>
                          </Link>
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.registerDate}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.status}
                        </TableCell>
                        <TableCell style={{ width: 30 }} align="center">
                          <ColorDot type={row.color || "expired"} />
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.priority}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.citeType}
                        </TableCell>
                        <TableCell style={{ width: 160 }} align="center">
                          {row.visitDate}
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows === 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} align={"center"}>
                          No hay datos para mostrar
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <TablePagination
              rowsPerPageOptions={counterPage()}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              backIconButtonText="Página anterior"
              nextIconButtonText="Página siguiente"
              labelRowsPerPage="Columnas por página:"
            />
          </>
        ))}
      {open && <ModalError open={open} close={handleClose} />}
      {spinner && <Spinner />}
      <Excel ref={excelRef} data={exportData} />
    </Grid>
  );
}
export default Visit;
