import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { getImputable, getMotive } from "../service/VisitService";

export default function ModalRejected({
  reject,
  handleChange,
  handleSaveReject,
  close,
}) {
  const [imputable, setImputable] = React.useState([]);
  const [motive, setMotive] = React.useState([]);
  useEffect(() => {
    getImputable()
      .then((res) => {
        setImputable(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    getMotive()
      .then((res) => {
        setMotive(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const buildImputable = () => {
    let list = [];
    list.push(
      <MenuItem key={-1} value={-1} disabled={true}>
        Selecciona un imputable
      </MenuItem>
    );
    imputable.forEach((d, index) => {
      list.push(
        <MenuItem key={index} value={index}>
          {d.shortDesc}
        </MenuItem>
      );
    });
    return list;
  };

  const buildMotive = () => {
    let list = [];
    list.push(
      <MenuItem key={-1} value={-1} disabled={true}>
        Selecciona un motivo
      </MenuItem>
    );
    motive.forEach((d, index) => {
      list.push(
        <MenuItem key={index} value={index}>
          {d.shortDesc}
        </MenuItem>
      );
    });
    return list;
  };

  return (
    <div>
      <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Motivo de rechazo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para rechazar la visita selecciona los motivos e ingresa una
            descripción por el cual la visita no se puede concretar.
          </DialogContentText>
          <FormControl fullWidth={true} style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Imputable</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reject.imputable}
              fullWidth={true}
              name={"imputable"}
              onChange={handleChange}
            >
              {buildImputable()}
            </Select>
          </FormControl>
          <FormControl fullWidth={true} style={{ marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reject.motive}
              name={"motive"}
              onChange={handleChange}
              fullWidth={true}
            >
              {buildMotive()}
            </Select>
          </FormControl>
          <TextField
            id="outlined-multiline-flexible"
            label="Descripción"
            name={"description"}
            multiline
            fullWidth={true}
            rowsMax={4}
            onChange={handleChange}
            value={reject.description}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} color="primary" onClick={close}>
            Cancelar
          </Button>
          <Button variant={"contained"} color="primary" onClick={handleSaveReject}>
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
