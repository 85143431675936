import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ColorDot from "./ColorDot";

export default function ({ type, value, onChange, label }) {
  return (
    <div onClick={onChange} style={{ display: "flex", margin: "4px 8px" }}>
      <ColorDot type={type} value={value} />
      <label style={{ marginLeft: "4px", userSelect: "none" }}>{label}</label>
    </div>
  );
}
