import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getVisits = async function () {
  const response = await axios.get(BASE_URL + "/adm/location/visit/visits");
  return response.data;
};
export const postGetVisits = async function (searchInfo) {
  console.log({ searchInfo });
  const response = await axios.post(
    BASE_URL + "/adm/location/visit/visits-post",
    searchInfo
  );
  return response.data;
};
export const getContactInfo = async function (serviceId) {
  let data = {
    serviceId: serviceId,
  };
  const response = await axios.post(
    BASE_URL + "/adm/location/visit/get-contact-info",
    data
  );
  return response.data.data;
};
export const getVisitsInfo = async function (ids) {
  let data = {
    serviceId: ids,
  };
  const response = await axios.post(
    BASE_URL + "/adm/location/visit/get-visits-info",
    data
  );
  return response.data;
};
export const getProjects = async function () {
  const response = await axios.get(
    BASE_URL + "/adm/location/visit/get-projects"
  );
  return response.data.data;
};
export const getStructures = async function () {
  const response = await axios.get(
    BASE_URL + "/adm/location/visit/get-structures"
  );
  return response.data.data;
};
export const getChannels = async function () {
  const response = await axios.get(
    BASE_URL + "/adm/location/visit/get-channels"
  );
  return response.data.data;
};

export const saveVisit = async function (
  location,
  data,
  serviceId,
  comCoor,
  fisCoor
) {
  let visit = {
    location: location.name,
    locationKey: location.key,
    countryId:
      location.address === 1
        ? parseInt(data.commercialAddress.country)
        : parseInt(data.fiscalAddress.country),
    zipCode:
      location.address === 1
        ? data.commercialAddress.zipCode
        : data.fiscalAddress.zipCode,
    zipCodeId:
      location.address === 1
        ? data.commercialAddress.zipCodeId
        : data.fiscalAddress.zipCodeId,
    phoneNumber:
      location.address === 1
        ? data.commercialAddress.phoneNumber
        : data.fiscalAddress.phoneNumber,
    address:
      location.address === 1
        ? data.commercialAddress.street + " " + data.commercialAddress.exNum
        : data.fiscalAddress.street + " " + data.fiscalAddress.exNum,
    longitude: location.address === 1 ? comCoor.lng : fisCoor.lng,
    latitude: location.address === 1 ? comCoor.lat : fisCoor.lat,
    structure: location.structure,
    channel: location.channel,
    projectId: location.project,
    visitDate: data.visitDate ? data.visitDate + "T00:00" : null,
    programacionDate: data.programDate ? data.programDate + "T00:00" : null,
    comments: data.comments,
    serviceId: serviceId,
  };

  console.log("saveVisit arg", visit);

  return axios
    .post(BASE_URL + "/adm/location/visit/save-visit", visit)
    .then((resp) => {
      console.log("saveVisit reponse", resp);
      return resp.data;
    });
};
export const getMotive = async function () {
  const response = await axios.get(BASE_URL + "/adm/location/visit/get-motive");
  return response.data;
};
export const getImputable = async function () {
  const response = await axios.get(
    BASE_URL + "/adm/location/visit/get-imputable"
  );
  return response.data;
};
export const saveReject = async function (serviceId, reject) {
  const parameters = {
    serviceId: serviceId,
    motiveItem: 68,
    motive: reject.motiveDesc,
    imputableItem: 69,
    imputable: reject.imputableDesc,
    description: reject.description,
  };
  const response = await axios.post(
    BASE_URL + "/adm/location/visit/reject-visit",
    parameters
  );
  return response.data;
};

export const changeVisitDate = async function (serviceId, newVisitDate) {
  const body = {
    visitDate: newVisitDate,
    serviceId,
  };

  const response = await axios.post(
    BASE_URL + "/adm/location/visit/update-date-visit",
    body
  );

  return response.data;
};
