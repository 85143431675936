import React from "react";
import Card from "../muiFacade/Card";
import Map from "../Map";

const MapCard = ({ location, setComCoor, setFisCoor, comCoor, fisCoor }) => {
  return (
    <Card
      style={{
        height: "600px",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "relative", height: "100%", width: "1000%" }}>
        <Map
          setLat={
            location.address === 1
              ? (arg) => {
                  setComCoor({ ...comCoor, lat: arg });
                }
              : (arg) => {
                  setFisCoor({ ...fisCoor, lat: arg });
                }
          }
          setLon={
            location.address === 1
              ? (arg) => {
                  setComCoor({ ...comCoor, lng: arg });
                }
              : (arg) => {
                  setFisCoor({ ...fisCoor, lng: arg });
                }
          }
          lan={location.address === 1 ? comCoor.lat : fisCoor.lat}
          lon={location.address === 1 ? comCoor.lng : fisCoor.lng}
        />
      </div>
    </Card>
  );
};

export default MapCard;
